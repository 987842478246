

















import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

export default defineComponent({
  name: "GenerateTkModal",
  props: {
    cbOnConfirmAction: {
      type: Function,
      default: () => 1,
    },
    isGenerateBtn: {
      type: Boolean,
    },
  },
  computed: {
    modalTitle(): string {
      return this.isGenerateBtn
        ? "Вы уверены, что хотите сгенерировать ТК? После подтверждения будет начат процесс генерации ТК"
        : "Вы уверены, что хотите переместить сгенерированные ТК и инициировать процесс получения данных ТК в ЦХЭД?";
    },
  },
  methods: {
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
    confirmActionCb() {
      (this as { cbOnConfirmAction: () => void }).cbOnConfirmAction?.();
      this.closeModal();
    },
  },
});
